import { inject, Injectable } from '@angular/core';
import { CustomersStore } from '@feature/customers/customers.store';
import { UserStore } from '@feature/user/user.store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

export interface AuthUserData {
	email: string;
	email_verified: boolean;
	given_name: string;
	groups: string[];
	name: string;
	nickname: string;
	nonce: string;
	preferred_username: string;
	sub: string;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	readonly userStore = inject(UserStore);
	readonly customersStore = inject(CustomersStore);

	subscription$?: Subscription;

	constructor(private oidcSecurityService: OidcSecurityService) {
		this.subscription$ = this.oidcSecurityService.userData$.subscribe((response) => {
			const { userData } = response;

			if (!userData) {
				return;
			}

			this.userStore.updateUser({
				name: userData?.name,
				email: userData?.email,
				givenName: userData?.given_name,
				nickname: userData?.nickname,
				groups: userData?.groups,
				sub: userData?.sub,
			});
		});
	}

	isAutheticated$() {
		return this.oidcSecurityService.isAuthenticated$;
	}

	login() {
		this.oidcSecurityService.authorize();
	}

	logout() {
		this.subscription$?.unsubscribe();
		this.userStore.reset();
		this.customersStore.reset();
		return this.oidcSecurityService.logoff();
	}

	checkAuth() {
		return this.oidcSecurityService.checkAuth();
	}

	refreshToken() {
		return this.oidcSecurityService.forceRefreshSession();
	}
}
